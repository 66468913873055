@use 'fonts'
@import 'shadows'
@import 'colours'

// Large version

.parallax
    min-height: 1000px
    // min-height: 100vh
    //min-height: 65vh // Minimum height is 65% of viewport height
    background-attachment: fixed
    background-size: cover
    background-position: 50% 50%

    p
        font-size: 24px
        color: #f5f5f5
        text-align: center
        line-height: 60px

    h1
        color: #fff
        font-size: 60px
        text-align: center
        padding-top: 60px
        line-height: 100px


// Compact version

.jumbotron-compact
    margin-bottom: 0
    border-radius: 0

    .row
        margin-left: 2rem
        margin-right: 2rem

        // TODO: Remove this because: 1) it appears unused, and 2) .jumbotron-caption and other places duplicate much of it.
    /*
        .jumbotron-caption-compact
            position: absolute
            top: 200px
            left: 6rem
            // @media screen and (min-width: 768px)
            //     position: absolute
            //     top: 200px
            //     left: 6rem
            // else
            //     position: absolute
            //     top: 200px
            //     left: 3rem
            width: 45vw
            max-width: 450px
            padding: 4rem
            padding-top: 7rem
            
            
            h1
                font-size: 3.5rem
                font-weight: 800
                letter-spacing: 0.1rem
                padding-top: 0
                padding-bottom: 2rem
                padding-right: 8rem
                margin: 0
                margin-bottom: 1rem
                line-height: 4rem
                // text-transform: capitalize

            p
                padding: 0
                margin: 0
                line-height: 3rem
                font-size: 2.5rem

            .date
                margin-top: 5rem
                font-size: 2.5rem
                font-weight: 800
                color: #fff

            .btn
                margin-bottom: 1rem
                margin-top: 4rem
                margin-left: 0
                padding: 0 1rem 0 1rem
                height: 5rem
                font-size: 2rem
                font-weight: 800
                background-color: $deepspace-blue
                border-color: #fff
                color: #fff
                text-transform: none */

.parallax-compact
    min-height: 638px
    // min-height: 100vh
    // min-height: 65vh // Minimum height is 65% of viewport height
    background-attachment: fixed
    background-size: cover
    background-position: 50% 50%
    position: relative
    // top: -100px
    // margin-bottom: -100px

.parallax-big
    min-height: 100vh
    // min-height: 65vh // Minimum height is 65% of viewport height
    background-attachment: fixed
    background-size: cover
    background-position: 50% 50%
    position: relative
    // top: -100px
    // margin-bottom: -100px

.parallax-slim
    height: 480px
    background-attachment: fixed
    background-size: cover
    background-position: 50% 50%
    position: relative
    // top: -100px
    // margin-bottom: -100px

// Note: styles common to both types of hero description are here, styles specific to one or the other kind are
// underneath.
//
// The 'transparent hero description' is most heros, with a frosted-glass effect rectangle with a title.
//
// The 'new hero description' is mostly for events/news, and has an opaque box with a title, intro and date.
#new-hero-description,
#transparent-hero-description
    
    color: #fff
    padding-left: 1rem
    padding-right: 1rem

    .row
        margin-left: 0
        margin-right: 0

    p
        margin-bottom: 5rem
        line-height: 1.1

    .hero-intro
        margin: 0
        padding: 0
        width: 100%

        p
            margin-bottom: 5rem
            line-height: 1.1
        
        h1
            text-align: left
            font-size: 3.5rem
            font-weight: 800
            letter-spacing: 0.1rem
            line-height: 4rem
            // text-transform: capitalize

        .jumbotron-caption
            width: 45vw
            margin: 1rem

            h1
                font-size: 3.5rem
                font-weight: 800
                letter-spacing: 0.1rem
                padding-top: 0
                padding-bottom: 2rem
                padding-right: 8rem
                margin: 0
                margin-bottom: 1rem
                line-height: 4rem
                // text-transform: capitalize

            p
                padding: 0
                margin: 0
                line-height: 3rem
                font-size: 2.5rem

            .date
                margin-top: 5rem
                font-size: 2.5rem
                font-weight: 800
                color: #fff

#new-hero-description
    .hero-intro
        margin-top: 4rem
        margin-bottom: 10rem
        max-width: 1250px

        .jumbotron-caption
            max-width: 450px
            padding: 4rem
            padding-top: 7rem

            background: rgba($deepspace-blue, 0.9)

#transparent-hero-description
    .hero-intro
        margin-top: 325px
        margin-bottom: 1rem
        max-width: 1250px

        .jumbotron-caption
            padding: 1rem
            width: 75vw
            max-width: 65%

            @media (max-width: 767.98px)
                max-width: 100%
                width: 100%
                padding-left: 10px
                padding-right: 10px
                margin-left: 0
                margin-right: 0

#big-hero-description
    .hero-intro
        .jumbotron-caption
            color: #ffffff
            .hero-intro-paragraph
                .crx-grid
                    .row
                        margin-left: 0
                        padding-left: 0
                        margin-right: 0
                        padding-right: 0
                        .col-md
                            margin-left: 0
                            padding-left: 0
